import { apiFetchListMember } from "../../api/member";

const namespaced = true;

const defaultState = () => {
    return {
        main: {
            list: [],
            detail: {},
            pagination: {},
            dialogSuspend:false,
            dialogLock:false,
            dialogMemberEdit:false,
            dialogUnSuspend:false,
            dialogUnverified:false,
            search: {},
            total: 0,
            page: 'list',
            dialog: {
                lock: false,
                unverify: false,
                suspend: false,
                editmain: false,
                image: false,
                databank: false,
            }
        },
        editmember:{
            formEdit:{
                userId : "",
                edit_name: "",
                edit_email: "",
                edit_phone: "",
            }
        },
        suspendmember:{
            formEdit:{
                userId : "",
                edit_reason: "",
            }
        },
        unsuspendmember:{
            formEdit:{
                userId : "",
                edit_reason: "",
            }
        },
        unverified:{
            formEdit:{
                userId : "",
            }
        }
    }
}

const state = defaultState()

const mutations = {
    setMainDialogLock: (state, payload) => {
        state.main.dialogLock = payload
    },
    setMainDialogMemberEdit: (state, payload) => {
        state.main.dialogMemberEdit = payload
    },
    setSuspendDialogMember: (state, payload) => {
        state.main.dialogSuspend = payload
    },
    setMainFormSuspend: (state, payload) => {
        state.suspendmember.formEdit.userId = payload.id
    },
    setUnSuspendDialogMember: (state, payload) => {
        state.main.dialogUnSuspend = payload
    },
    setMainFormUnSuspend: (state, payload) => {
        state.unsuspendmember.formEdit.userId = payload.id
    },
    setUnverifiedDialogMember: (state, payload) => {
        state.main.dialogUnverified = payload
    },
    setMainFormUnverified: (state, payload) => {
        state.unverified.formEdit.userId = payload.id
    },
    setMainFormEdit: (state, payload) => {
        state.editmember.formEdit.userId = payload.id
        state.editmember.formEdit.edit_name = payload.name
        state.editmember.formEdit.edit_email = payload.email
        state.editmember.formEdit.edit_phone = payload.phone
    },
    setMainList: (state, payload) => {
        state.main.list = payload
    },
    setMainDetail: (state, payload) => {
        state.main.detail = payload
    },
    setMainTotal: (state, payload) => {
        state.main.total = payload
    },
    setMainSearch: (state, payload) => {
        state.main.search = payload
        state.main.search.page = state.main.pagination.page
        state.main.search.itemsPerPage = state.main.pagination.itemsPerPage
    },
    setMainPaginationSetting: (state, payload) => {
        state.main.pagination = payload
        state.main.search.page = payload.page
        state.main.search.itemsPerPage = payload.itemsPerPage
    },
    setMainPage: (state, payload) => {
        state.main.page = payload
    },
    setMainDialog: (state, payload) => {
        switch (payload) {
            case "lock balance":
                state.main.dialog.lock = !state.main.dialog.lock
                break;
            case "unverify":
                state.main.dialog.unverify = !state.main.dialog.unverify
                break;
            case "suspend":
                state.main.dialog.suspend = !state.main.dialog.suspend
                break;
            case "edit main":
                state.main.dialog.editmain = !state.main.dialog.editmain
                break;
            case "image":
                state.main.dialog.image = !state.main.dialog.image
                break
            case "data bank":
                state.main.dialog.databank = !state.main.dialog.databank
                break
            default:
                break;
        }
    }
}

const actions = {
    fetchMainList({ commit, state }) {
        return new Promise((resolve, reject) => {
            apiFetchListMember(state.main.search)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setMainList', res.member)
                        commit('setMainTotal', res.total)
                        resolve(res)
                    } else {
                        reject(res)
                    }
                }).catch((res) => reject(res))
        })
    },
    getMemberDetail({ commit }, payload) {
        return new Promise((resolve, reject) => {
            apiFetchListMember(payload)
                .then((res) => {
                    if (res.status == 200) {
                        commit('setMainDetail', res.member[0])

                        resolve(res)
                    }
                    reject(res)
                }).catch(res => reject(res))
        })
    },
}

export default { namespaced, state, mutations, actions }
<template>
<div>
    <!-- <v-btn color="success" @click="fetch">Add New MemberS New Baru</v-btn> -->

<CustomCard>

    <template v-slot:toolbar>
        <v-btn color="success" small outlined @click="filterToggle">
        filter <v-icon>mdi-chevron-down</v-icon>
        </v-btn>

    </template>

        <template v-slot:expansion>
            <v-expansion-panels v-model="panel" multiple>
            <v-expansion-panel expand focusable>
                <v-expansion-panel-content>
                    
                <v-layout>

                        <v-flex>
                            <v-text-field
                                v-model="searchForm.member"
                                label="(Name, Email, Payment No, Ref ID, iPaymu ID)"
                                single-line
                                small
                                @keyup.13="fetch(1)"
                                clearable>
                            </v-text-field>
                        </v-flex>

                        <!-- <v-flex>
                            <v-autocomplete
                                v-model="searchForm.thirdparty"
                                :items="thirdparty"
                                item-text="text"
                                item-value="value"
                                label="ThirdParty Tipe"
                            >
                            </v-autocomplete>
                        </v-flex> -->
                    
                    <v-flex>
                        <v-autocomplete
                            v-model="searchForm.statusPembayaran"
                            :items="statusPembayaran"
                            item-text="text"
                            item-value="value"
                            label="Status"
                        >
                        </v-autocomplete>
                    </v-flex>


                    <v-flex>
                        <v-autocomplete
                            v-model="searchForm.typetransaksi"
                            :items="typetransaksi"
                            item-text="text"
                            item-value="value"
                            label="RPX Service"
                        >
                        </v-autocomplete>
                    </v-flex>
                    
                </v-layout>

                <v-layout>
    
                    <v-flex>

                            <v-menu
                                :close-on-content-click="false"
                                :return-value.sync="date"
                                transition="scale-transition"
                                offset-y
                                min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="searchForm.date"
                                    label="Start Date"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    clearable
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                v-model="searchForm.date"
                                no-title
                                scrollable
                                >
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="menu = false"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.menu.save(date)"
                                >
                                    OK
                                </v-btn>
                                </v-date-picker>
                            </v-menu>

                    </v-flex>

                    <v-flex>
                        
                        <v-menu
                                :close-on-content-click="false"
                                :return-value.sync="date2"
                                transition="scale-transition"
                                offset-y
                                min-width="auto">

                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="searchForm.date2"
                                    label="End Date"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    clearable
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>

                            <v-date-picker
                                v-model="searchForm.date2"
                                no-title
                                scrollable
                                >
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="menu = false"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.menu.save(date)"
                                >
                                    OK
                                </v-btn>
                            </v-date-picker>
                            
                        </v-menu>

                    </v-flex>

                </v-layout>

                <v-layout align-end justify-end>
                    <v-spacer></v-spacer>
                    <v-btn
                    small
                    depressed
                    color="success"
                    class="m0"
                    @click="fetch(1)"
                    >
                    <v-icon>mdi-magnify</v-icon> Search
                    </v-btn>
                </v-layout>
                </v-expansion-panel-content>
            </v-expansion-panel>
            </v-expansion-panels>
        </template>

        <template v-slot:datatable>

            <v-flex md12 style="overflow-x: scroll">
                    <v-data-table
                        :headers="headers"
                        :items="$store.state.transaction.hisgold.list"
                        :options.sync="pagination"
                        :server-items-length="$store.state.transaction.hisgold.total"
                        :footer-props="{
                        'items-per-page-options': [10, 25, 50, 100],
                        'items-per-page-text': 'Baris per Halaman:',
                        }"
                        :items-per-page="10"
                        :loading="loading"
                        :loading-text="loadingtext">

                        <template v-slot:[`item.member`]="{ item }">
                                <div>  <b> {{ item.name }} </b> </div>
                                <div>  {{ item.email }} </div>
                        </template>

                        <template v-slot:[`item.amountall`]="{ item }">
                                <div>  Rp. <b> {{ item.amount }} </b> </div>
                        </template>

                        <template v-slot:[`item.totalgr`]="{ item }">
                                <div>  <b> {{ item.gramasi }} </b> GR </div>
                                <div>  Rp. {{ item.sell_rate }} </div>
                        </template>

                        <template v-slot:[`item.kepingemas`]="{ item }">
                                <!-- <div>  <b> {{ item.buy_rate }} </b> </div>
                                <div>  {{ item.sell_rate }} </div> -->
                                <div v-html="item.keping"></div>
                        </template>

                        <template v-slot:[`item.biayafee`]="{ item }">
                                <!-- <div>  <b> {{ item.buy_rate }} </b> </div>
                                <div>  {{ item.sell_rate }} </div> -->
                                <v-row>
                                    <v-col sm="3" md="3" style="fontSize:12px; padding:0; margin:0;">
                                        Fee
                                    </v-col>
                                    <v-col sm="1" md="1" style="fontSize:12px; padding:0; margin:0;">
                                        :
                                    </v-col>
                                    <v-col sm="7" md="7" style="fontSize:12px; padding:0; margin:0;">
                                       {{ item.fee }}
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col sm="3" md="3" style="fontSize:12px; padding:0; margin:0;">
                                        Ongkir
                                    </v-col>
                                    <v-col sm="1" md="1" style="fontSize:12px; padding:0; margin:0;">
                                        :
                                    </v-col>
                                    <v-col sm="7" md="7" style="fontSize:12px; padding:0; margin:0;">
                                       Rp. {{ item.ongkir }}
                                    </v-col>
                                </v-row>

                        </template>

                        <template v-slot:[`item.tipepengambilan`]="{ item }">
                                <!-- <div>  <b> {{ item.buy_rate }} </b> </div>
                                <div>  {{ item.sell_rate }} </div> -->
                                <div v-html="item.tipe_pengambilan"></div>
                        </template>
                        
                        <template v-slot:item.action="{ item }">

                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    color="info"
                                    icon
                                    @click="showDetail(item)"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-icon small>mdi-eye</v-icon>
                                </v-btn>
                                </template>
                                <span>Detail</span>
                            </v-tooltip>

                        </template>
                        
                        <template v-slot:item.email="{ item }">
                            <div v-if="item.type_code == 1 || item.type_code == 10">
                                {{ item.receiver_email }}
                            </div>
                            <div v-else>
                                {{ item.sender_email }}
                            </div>
                        </template>

                    </v-data-table>        
            </v-flex>

    </template>

</CustomCard>

</div>
</template>

<script>
import CustomCard from "../../../../components/CustomCard.vue";

export default {
  data() {
    return {
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      modal: false,
      panel: null,
      profiles:this.$store.state.auth.profile,
      provinsiList:[],
      currentPage:1,
      decimals:"10.00",
      icons:"chevron-right",
      statusPembayaran: [
            { text: "All Status", value: "" },
            { text: "Pending", value: 0 },
            { text: "Proses Pengiriman", value: 1 },
            { text: "Cancel", value: 2 }
            // { text: "Gagal", value: "2" },
      ],
      typetransaksi: [
            { text: "All Type", value: "" },
            { text: "NDP", value: "NDP" },
            { text: "SDP", value: "SDP" }
            // { text: "Gagal", value: "2" },
      ],
      thirdparty: [
            { text: "All", value: "all" },
            { text: "IDCASH", value: "idcash" }
            // { text: "Gagal", value: "2" },
      ],
      statusver: [
            { text: "All", value: "all" },
            { text: "Request Verfikasi", value: "false" },
            { text: "Terverifikasi", value: "true" }
            // { text: "Gagal", value: "2" },
      ],
      statusaddr: [
            { text: "All", value: "all" },
            { text: "Address + Current Address", value: "1" },
            { text: "Address", value: "2" },
            { text: "No Address", value: "3" }
            // { text: "Gagal", value: "2" },
      ],
      searchForm: {
        member: ''
        // tipe: 'ALL',
        // status: 'ALL',
        // sponsor: ''
      },
      formLock:{

      },
      tipe: [],
      menu: null,
      form: {},
      pagination: {},
      headers: [
        { text: "Tanggal", value: "created_at" },
        { text: "Member", value: "member" },
        { text: "Keping Cetak", value: "kepingemas" , width:"150" },
        { text: "Total Gr/Rate", value: "totalgr" , width:"150" },
        { text: "Amount", value: "amountall", width:"180"  },
        { text: "Biaya", value: "biayafee" , width:"180" },
        { text: "Pengambilan", value: "tipepengambilan" },
        { text: "Status", value: "verified" },
        { text: "Action", value: "action" },
      ],
      loading: false,
      loadingText: "Loading Data...",
    };
  },
  methods: {
    fetch(cari) {
      if(cari == 1){
          this.pagination.page = 1;
      }
      this.$store.commit("main/setLoading", true);
      this.loading = true;
      this.loadingtext = 'Loading... Please wait';

      //alert("limits "+this.pagination.itemsPerPage);
      this.$store
        .dispatch("transaction/fetchMemberListHisGold", {
            page: this.pagination.page,
            itemsPerPage: this.pagination.itemsPerPage,
            index:"",
            date1:this.searchForm.date,
            date2:this.searchForm.date2,
            member:this.searchForm.member,
            service:this.searchForm.typetransaksi,
            status:this.searchForm.statusPembayaran
        })
        .then(() => {
          this.$store.commit("main/setLoading", false);
          this.loading = false;
          this.loadingtext = '';
        })
        .catch((res) => {
          this.$store.commit("main/setLoading", false);
          this.$store.commit("main/setSnackbarFail", res.response.data.message);
          this.loading = false;
          this.loadingtext = '';
        });
    },
    filterToggle() {
      if (this.panel == null)
        this.panel = [...Array(this.items).keys()].map((k, i) => i);
      else this.panel = null;
    },
    editVerificationBan: function(dialog , id , code){
    //  console.log("updateId" , this.profiles.id);
    //  console.log("code" , code);
    //  console.log("trxid" , id);
     //console.log("id" , id);
      this.$store.commit("main/setLoading", true);
      //alert("ban");
      this.$store
        .dispatch("transaction/editMemberTransactionBan", {
            updateId: this.profiles.id,
            trxid: id,
            code: code,
            id: id
        })
        .then(() => {
            this.fetch(this.pagination.page);
            dialog.value = false;
            this.$store.commit("main/setLoading", false);
            this.$store.commit("main/setSnackbarSuccess", "Verify Data Successfully");
        })
        .catch((res) => {
          this.fetch(this.pagination.page);
          dialog.value = false;
          this.$store.commit("main/setLoading", false);
          this.$store.commit("main/setSnackbarFail", res);
          this.loading = false;
          this.loadingtext = '';
        });
    },
    editVerificationSuccess: function(dialog , id , code){
     this.$store.commit("main/setLoading", true);
      this.$store
        .dispatch("transaction/editMemberTransactionSuccess", {
            updateId: this.profiles.id,
            code: code,
            trxid: id,
            id: id
        })
        .then(() => {
            this.fetch(this.pagination.page);
            dialog.value = false;
            this.$store.commit("main/setLoading", false);
            this.$store.commit("main/setSnackbarSuccess", "Verify Data Successfully");
        })
        .catch((res) => {
          this.fetch(this.pagination.page);
          dialog.value = false;
          this.$store.commit("main/setLoading", false);
          this.$store.commit("main/setSnackbarFail", res);
          this.loading = false;
          this.loadingtext = '';
        });
    },
    userLock: function(dialog , id , code){

        this.$store.commit("main/setLoading", true);

        this.$store
            .dispatch("member/editUserLockMember", {
                updateId: this.profiles.id,
                code: code,
                id: id
            })
        .then(() => {
            this.fetch(this.pagination.page);
            dialog.value = false;
            this.$store.commit("main/setLoading", false);
            this.$store.commit("main/setSnackbarSuccess", "Verify Data Successfully");
        })
        .catch((res) => {
            this.fetch(this.pagination.page);
            dialog.value = false;
            this.$store.commit("main/setLoading", false);
            this.$store.commit("main/setSnackbarFail", res);
        });

    },
    showDetail(item) {
        //this.$store.commit("main/setLoading", false);
        //this.$store.commit("main/setSnackbarFail", res.response.data.message);
        //this.$store.state.member.member.paging_page = this.pagination.page;
        //alert("tes");
        this.$store.commit("transaction/setMemberHisGoldPage", "detail");
        this.$store.commit("transaction/setMemberTrxHisGold", item);
        //console.log("ItemList" , item);
        this.loading = false;
        this.loadingtext = '';
    },

    showExpand: function (element,index){
        const detailElement = document.getElementById(index);
        if(detailElement.classList.contains('hidden')){
            element.target.classList.remove("mdi-chevron-right");
            element.target.classList.add("mdi-chevron-down");
            detailElement.classList.remove("hidden");
            //this.icons = "chevron-down";
        }
        else{
            element.target.classList.add("mdi-chevron-right");
            element.target.classList.remove("mdi-chevron-down");
            detailElement.classList.add("hidden");
            //this.icons = "chevron-right";
        }   
    },
    showLock:function(userId,real_gramasi,lock_user,lock_dinaran){
      this.formLock.userId = userId
      this.formLock.last_balance = real_gramasi
      this.formLock.lock_user = lock_user
      this.formLock.lock_dinaran = lock_dinaran
    },
  },
  mounted() {
      //alert("tess");
  },
  watch: {
    pagination() {
        //alert("tess");
        //alert(this.$store.state.member.member.is_back);
        //if(this.$store.state.member.member.is_back != 1){
            this.loading = true
            this.loadingtext = 'Loading... Please wait'
            // alert("tes");
            //console.log("user_con" , "nils "+this.profiles.id);
            //alert("tes");
            
            this.fetch(0)
        //}

        //if(this.$store.state.member.member.is_back == 1){
        //this.pagination.page = this.$store.state.member.member.paging_page;
        //}

       // this.$store.state.member.member.is_back = 0;

    },
    searchSponsor(val) {
        val && val !== this.searchForm.sponsor && this.getSponsor(val);
    },
    searchMember(val) {
        val && val !== this.searchForm.member && this.getMember(val);
    }
  },
  
  computed: {
    dateRangeText() {
      if (typeof this.form.dates != "undefined") {
        if (this.form.dates.length > 1) {
          return this.form.dates.join(" ~ ");
        } else {
          return this.form.dates[0];
        }
      } else {
        return null;
      }
    },
  },
  components: { CustomCard },
};
</script>

<style>
</style>